import React, { FC, useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { AlignLeft, CheckSquare, Plus, Target } from 'react-feather';

import { Button, TypeButton } from '@maxi-innovation/ui-kit-frontend';
import { SurveyTextField, DateFields, SurveyView, SwitchField, SurveyPreview } from './fields';
import { ScrollToTopOnMount } from 'core/components';
import { Question } from './questions';
import {
	addQuestionAction,
	changeQuestionTypeAction,
	clearAllQuestions,
	setSurveyAction,
	updateSurveyAnonymousAction,
	updateSurveyDescriptionAction,
	updateSurveyFinishButtonLinkAction,
	updateSurveyFinishButtonNameAction,
	updateSurveyFinishTextAction,
	updateSurveySpecialAction,
	updateSurveyTitleAction,
} from 'action/survey';
import useClickOutside from 'hooks/useClickOutside';
import { ISurvey } from './types';
import { createSurveyRest, getSurveyForAdminRest, updateSurveyRest } from 'shared/api/survey';
import { NotificationHelper } from 'action/notificationHelper';

import './style.scss';

interface ISelector {
	survey: ISurvey;
	shortUserInfo: any;
}

export const Form: FC = () => {
	const { id } = useParams<{ id: string }>();

	const history = useHistory();
	const dropdownRef = useRef<HTMLDivElement>(null);
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [notCover, setNotCover] = useState(false);
	const [selectQuestion, setSelectQuestion] = useState(false);
	const [fixUsers, setFixUsers] = useState(0); // для обновления поля с выбором пользователей

	const { survey, roles } = useSelector((state: ISelector) => ({
		survey: state.survey,
		roles: state.shortUserInfo.roles,
	}));
	const techAdm = roles.includes('ROLE_TECHNICAL_ADMINISTRATOR');

	useEffect(() => {
		return () => {
			dispatch(clearAllQuestions());
		};
	}, [dispatch]);

	useEffect(() => {
		(async () => {
			if (!id) return;

			const { response, responseCode, errorMessage } = await getSurveyForAdminRest(id);

			if (responseCode) {
				dispatch(NotificationHelper(errorMessage, responseCode));
			} else {
				const data = {
					...response,
					questions: response.questions.map((question: any, index: number) => {
						return {
							type: question.answerType,
							title: question.text,
							...(question.answerType === 'SCALE'
								? {
										max: question.possibleAnswers.length,
										minLabel: question.minLabel,
										maxLabel: question.maxLabel,
								  }
								: {
										possibleAnswers: question.possibleAnswers.filter(
											(item: { ownAnswer: boolean }) =>
												!item.ownAnswer && item
										),
										withOwnAnswer: !!question.possibleAnswers.find(
											(item: { ownAnswer: boolean }) => item.ownAnswer
										),
								  }),
							id: question.id,
							index: index,
							questionNumber: index + 1,
							conditions: question.conditions.map((condition: any, index: number) => {
								return {
									questionType: response.questions.find(
										(item: any) => item.id === condition.questionId
									).answerType,
									id: condition.questionId,
									type: condition.type,
									value: condition.value,
									index: index,
									questionNumber: condition.questionNumber,
								};
							}),
							image: question.image,
						};
					}),
				};
				dispatch(setSurveyAction(data));
			}
		})();
	}, [id, dispatch]);

	const checkValidation = useCallback(() => {
		let isValid = true;

		setNotCover(!survey.cover);

		if (
			!survey.cover ||
			!survey.endDate ||
			!survey.startDate ||
			!survey.title ||
			!survey.questions
		) {
			isValid = false;
		}

		survey.questions.forEach(question => {
			if (!question.title) isValid = false;

			if (question.type === 'SCALE') {
				if (!question.max) isValid = false;
			}

			if (['SINGLE', 'MULTIPLE'].includes(question.type)) {
				if (!question.possibleAnswers) isValid = false;

				question.possibleAnswers?.forEach(answer => {
					if (!answer.text) isValid = false;
				});
			}

			question.conditions?.forEach(condition => {
				if (!condition.questionNumber || !condition.value) isValid = false;
			});
		});

		return isValid;
	}, [survey]);

	const onSubmit = async () => {
		setIsLoading(true);

		if (!checkValidation()) {
			setIsLoading(false);
			return;
		}

		const data = {
			coverId: survey.cover.id,
			title: survey.title,
			description: survey.description,
			startDate: survey.startDate,
			endDate: survey.endDate,
			anonymous: survey.anonymous,
			...(survey.special
				? techAdm && {
						finishText: survey.finishText,
						finishButtonName: survey.finishButtonName,
						finishButtonLink: survey.finishButtonLink,
				  }
				: {
						departments: survey.forDepartments
							? survey.forDepartments.map(item => item.id)
							: [],
						positions: survey.forPositions
							? survey.forPositions.map(item => item.id)
							: [],
						users: survey.forUsers ? survey.forUsers.map(item => item.id) : [],
				  }),
			...(techAdm && {
				special: survey.special,
			}),
			questions: survey.questions.map(question => {
				return {
					imageId: question.image?.id,
					text: question.title,
					answerType: question.type,
					max: question.max,
					minLabel: question.minLabel,
					maxLabel: question.maxLabel,
					possibleAnswers: question.possibleAnswers?.map(
						answer => answer.type !== 'OTHER' && answer.text
					),
					withOwnAnswer: question.withOwnAnswer,
					conditions: question.conditions?.map(condition => {
						return {
							questionNumber: condition.questionNumber,
							type: condition.type,
							value: condition.value === 'Другое' ? 'OTHER' : condition.value,
							ownAnswer: condition.value === 'OTHER' || condition.value === 'Другое',
						};
					}),
				};
			}),
		};

		const { responseCode, errorMessage } = !id
			? await createSurveyRest(data)
			: await updateSurveyRest({ ...data, id: +id });

		if (responseCode) {
			dispatch(NotificationHelper(errorMessage, responseCode));
			setIsLoading(false);
		} else {
			history.push('/services/survey');
		}
	};

	const closeMenuHandle = () => setSelectQuestion(false);
	useClickOutside(dropdownRef, closeMenuHandle);

	const getQuestionSchema = useCallback(
		(type: string) => {
			return {
				type,
				title: '',
				id: survey.questions.length
					? Math.max(...survey.questions.map((item: { id: number }) => item.id)) + 1
					: 1,
				...(type === 'SCALE'
					? { max: 4 }
					: type === 'TEXT'
					? undefined
					: { possibleAnswers: [{ id: 1, text: '' }], withOwnAnswer: false }),
				conditions: [],
				questionNumber: survey.questions.length + 1,
			};
		},
		[survey.questions]
	);

	const addQuestion = useCallback(
		(type: string) => {
			dispatch(addQuestionAction(getQuestionSchema(type)));
			setSelectQuestion(false);
		},
		[dispatch, getQuestionSchema]
	);

	const changeTypeHandler = useCallback(
		(type: string, questionNumber: number) => {
			dispatch(changeQuestionTypeAction(questionNumber, getQuestionSchema(type)));
		},
		[dispatch, getQuestionSchema]
	);

	const onChangeTitleSurveyHandler = useCallback(
		(value: string) => {
			dispatch(updateSurveyTitleAction(value));
		},
		[dispatch]
	);

	const onChangeSurveyFinishText = useCallback(
		(value: string) => {
			dispatch(updateSurveyFinishTextAction(value));
		},
		[dispatch]
	);

	const onChangeSurveyFinishButtonName = useCallback(
		(value: string) => {
			dispatch(updateSurveyFinishButtonNameAction(value));
		},
		[dispatch]
	);

	const onChangeSurveyFinishButtonLink = useCallback(
		(value: string) => {
			dispatch(updateSurveyFinishButtonLinkAction(value));
		},
		[dispatch]
	);

	const onChangeTitleDescriptionHandler = useCallback(
		(value: string) => {
			dispatch(updateSurveyDescriptionAction(value));
		},
		[dispatch]
	);

	const copyQuestion = useCallback(
		question => {
			const copyQuestion = getQuestionSchema(question.type);

			copyQuestion.title = question.title;

			// @ts-ignore
			if (question.max) copyQuestion.max = question.max;
			// @ts-ignore
			if (question.minLabel) copyQuestion.minLabel = question.minLabel;
			// @ts-ignore
			if (question.maxLabel) copyQuestion.maxLabel = question.maxLabel;

			// @ts-ignore
			copyQuestion.conditions = question.conditions.map(item => ({ ...item }));

			// @ts-ignore
			if (question.withOwnAnswer) copyQuestion.withOwnAnswer = question.withOwnAnswer;
			if (question.possibleAnswers) {
				// @ts-ignore
				copyQuestion.possibleAnswers = question.possibleAnswers.map(item => ({ ...item }));
			}
			dispatch(addQuestionAction(copyQuestion));
		},
		[dispatch, getQuestionSchema]
	);

	return (
		<div className="survey-form">
			<div className="create-survey-form-wrap">
				<div className="create-survey-form">
					<ScrollToTopOnMount />
					<SurveyPreview />

					<div className="create-survey-form__wrap">
						<div className="create-survey-form__part">
							<p className="create-survey-form__part-title">Настройки опроса</p>

							<SurveyTextField
								blockId="create-survey-title"
								error={!survey?.title}
								name="title"
								label="Название опроса"
								placeholder="Укажите название опроса"
								maxLength={255}
								onChange={onChangeTitleSurveyHandler}
								defaultValue={survey.title}
							/>

							<SurveyTextField
								blockId="create-survey-description"
								name="description"
								label="Описание"
								placeholder="Добавьте описание опроса"
								maxLength={1000}
								onChange={onChangeTitleDescriptionHandler}
								defaultValue={survey.description}
							/>

							<DateFields />
						</div>

						<div className="create-survey-form__part">
							<SwitchField
								defaultValue={survey.special}
								text="Публичный опрос"
								name="special"
								id="test-services_survey__special"
								change={(special: boolean) => {
									dispatch(updateSurveySpecialAction(special));
									if (special) dispatch(updateSurveyAnonymousAction(false));
								}}
								disabled={!techAdm}
							/>

							{survey.special ? (
								<>
									<SurveyTextField
										blockId="create-survey-finishText"
										error={!survey?.finishText}
										name="finishText"
										label="Текст обращения"
										placeholder="Укажите текст обращения"
										maxLength={1000}
										onChange={onChangeSurveyFinishText}
										defaultValue={survey.finishText}
										disabled={!techAdm}
									/>
									<SurveyTextField
										blockId="create-survey-finishButtonName"
										error={!survey?.finishButtonName}
										name="finishButtonName"
										label="Название кнопки"
										placeholder="Укажите название кнопки"
										maxLength={50}
										onChange={onChangeSurveyFinishButtonName}
										defaultValue={survey.finishButtonName}
										disabled={!techAdm}
									/>
									<SurveyTextField
										blockId="create-survey-finishButtonLink"
										error={!survey?.finishButtonLink}
										name="finishButtonLink"
										label="Ссылка для кнопки"
										placeholder="Укажите ссылку для кнопки"
										maxLength={255}
										onChange={onChangeSurveyFinishButtonLink}
										defaultValue={survey.finishButtonLink}
										disabled={!techAdm}
									/>
								</>
							) : (
								<>
									<p className="create-survey-form__part-title">
										Кому отображается опрос
									</p>

									<SurveyView
										key={fixUsers}
										fixViewComponent={() => setFixUsers(prev => prev + 1)}
									/>

									<SwitchField
										defaultValue={survey.anonymous}
										text="Анонимный опрос"
										name="anonymous"
										id="test-services_survey__anonymous"
										change={(anonymous: boolean) =>
											dispatch(updateSurveyAnonymousAction(anonymous))
										}
									/>
								</>
							)}
						</div>
					</div>
				</div>

				{!!survey?.questions &&
					survey.questions.map((item, index, arr) => (
						<Question
							key={item.id}
							index={index}
							lastItem={index + 1 === arr.length}
							firstItem={index === 0}
							questionSchema={item}
							changeTypeHandler={changeTypeHandler}
							copyQuestion={copyQuestion}
						/>
					))}
			</div>

			<div className="survey-add-question">
				<div className="survey-add-question__wrap" ref={dropdownRef}>
					<Button
						variant={TypeButton.PRIMARY}
						className="survey-add-question__button"
						round
						onClick={() => setSelectQuestion(prev => !prev)}
					>
						<Plus className="survey-add-question__icon" />
					</Button>
					{selectQuestion && (
						<div className="survey-questions-types">
							<div
								className="survey-questions-types__item"
								onClick={() => addQuestion('SINGLE')}
								id="survey-select-question-single"
							>
								<Target className="survey-questions-types__icon" />
								<span className="survey-questions-types__text">Один ответ</span>
							</div>
							<div
								className="survey-questions-types__item"
								onClick={() => addQuestion('MULTIPLE')}
								id="survey-select-question-multiple"
							>
								<CheckSquare className="survey-questions-types__icon" />
								<span className="survey-questions-types__text">
									Множественный выбор
								</span>
							</div>
							<div
								className="survey-questions-types__item"
								onClick={() => addQuestion('TEXT')}
								id="survey-select-question-texxt"
							>
								<AlignLeft className="survey-questions-types__icon" />
								<span className="survey-questions-types__text">Текст</span>
							</div>
							<div
								className="survey-questions-types__item"
								onClick={() => addQuestion('SCALE')}
								id="survey-select-question-scale"
							>
								<svg role="img" className="survey-questions-types__icon">
									<use
										xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#scale'}
									></use>
								</svg>
								<span className="survey-questions-types__text">Шкала</span>
							</div>
						</div>
					)}
				</div>
			</div>

			<div className="create-survey-form-finish">
				{notCover && (
					<p className="create-survey-form-finish__error">Добавьте изображение опроса</p>
				)}
				<Button
					variant={TypeButton.PRIMARY}
					type="button"
					className="create-survey-form-finish__button"
					id="button-create-survey"
					disabled={isLoading}
					onClick={onSubmit}
				>
					Сохранить
				</Button>
			</div>
		</div>
	);
};
