import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { clearStoreSearchResult, textColor } from '../../utils';
import * as Icon from 'react-feather';
import ReactGA from 'react-ga4';

export const PostItem = withRouter(
	({ groupId, groupName, id, text, location, fillText, publishDate, indexName, clickHandle }) => {
		const { state } = location;

		const clickPostItemHandler = () => {
			if (clickHandle) clickHandle();
			ReactGA.event({
				category: 'Переходы по результатам поиска',
				action: 'Переход в "Публикации"',
				label: 'Открытия детального просмотра статей',
			});

			clearStoreSearchResult();
		};

		return (
			<Link
				to={{
					pathname: `/group/${groupId}/${id}?fromIndex=${indexName}`,
					state: {
						...state,
						back: state && !!state.back ? state.back - 1 : -1,
					},
				}}
				className="block-item block-item_guide"
				onClick={clickPostItemHandler}
			>
				<svg role="img" className="stroke-svg">
					<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#search-post'} />
				</svg>
				<div className="block-item-info">
					<span className="block-item-short-text">{textColor(text, fillText)}</span>
					<div className="block-item-wrapper">
						<span className="block-item-folder">
							{textColor(publishDate, fillText)}
						</span>
						<div className="block-item-folder">
							<Icon.List size="18" className="block-item-featherIcon" />
							<span>{textColor(groupName, fillText)}</span>
						</div>
					</div>
				</div>
			</Link>
		);
	}
);
